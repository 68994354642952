/* ---------------------------------------------
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
/* --------------------------------------------- */

@import 'variable';

@import 'mixins/spacing';
@import 'mixins/color';
@import 'mixins/font';

@import 'component/global';
@import 'component/heading';

@import 'component/header';
@import 'component/sidebar';
@import 'component/spinner';
@import 'component/card';
@import 'component/dashboard';
@import 'component/modal';

@import 'responsive';

@import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";

/*added the seconday color for close button*/
.secondary {
  background-color: $secondary;
  color: white;
}

/* Header container */
.header-container {
  padding: 15px;
}

/* Header detail */
.header-details {
  row-gap: 10px !important;
  column-gap: 5px !important;
  padding: 0px 15px !important;
}

/* Page header (inside the headline) */
.page-headline app-page-header {
  margin: 0;
  padding: 0;
}

/* Parent container for header form*/
.header-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 70%;
}

/* Common styling for header form fields*/
.header-form-content {
  padding-top: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1; /* Common flex value for all fields */
  min-width: 150px; /* Ensures responsiveness */
  max-width: 250px;
}

/* Consistent width for form fields */
.header-form-content mat-form-field {
  width: 100%;
}

.drop-down{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding-top: 18px;
}

.mat-tab-label,
.mat-tab-link {
  color: #31afe5;

  &.mat-tab-disabled {
    color: #e4f4f1;
  }

}


/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
  color: white;
  background-color: #31afe5;
}

.countdown {
  color: #f44336;
}

.mat-header-row {
  background-color: #30AEE4;
  color: #FFFFFF;
}

.mat-header-cell {
  color: #ECF0F5;
  font-weight: medium;
  font-size: small;

}

.mat-cell,
.mat-header-cell {
  //border-right: 1px solid #ccc;
  padding: 2px;
}

.mat-cell {
  //color: #D8D8D8;
  font-size: smaller;

}

.mat-table {
  display: table;
  width: 100%;
  overflow-x: auto;
  border-collapse: separate;
}

@media screen and (max-width: 600px) {
  .mat-table {
    display: block;
    white-space: nowrap;
  }

  .mat-header-row,
  .mat-row {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
  }

  .mat-cell,
  .mat-header-cell {
    flex: 1;
    max-width: 100%;
    padding: 2px 2px 2px 0;
  }
}

.mat-row:hover {
  background-color: #EAEFF5;
  cursor: pointer;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: #EAEFF5;
}

/* for showing the horizontal scrollbar */
.table-container {
  width: 100vw;
  /* THIS WORKS for horizontal scrolling (all columns visibile)
   width: 100%;     THIS DOESNT for horizontal scrolling (no scrollbars)
   width: 2800px;   THIS ALSO DOESNT for horizontal scrolling (no scrollbars) */
}

/* for giving the space between the columns */

table {
  width: 100%;
}

/* Custom styles for mat-paginator */
.mat-paginator-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Move items per page selector to the left */
.mat-paginator-range-actions {
  order: 1;
}

/* Move page number indicators to the right */
.mat-paginator-navigation {
  order: 3;
}


.inner-table {
  width: 100%;
  font-size: small;
}

.mat-row:nth-child(odd) {
  background-color: white;
}

.mat-row:nth-child(even) {
  background-color: #fafafa;
}

/* Remove shadow from mat-card */
.mat-card {
  box-shadow: none !important;
}

/* Remove shadow from mat-table */
.mat-table {
  box-shadow: none !important;
  font-size: medium;
  border-radius: 5px;
  overflow: hidden;
}

/* Override the default appearance of mat-tab-label */
.mat-tab-label {
  border-radius: 5px, 5px, 5px, 0px;
  font-weight: bolder;
  color: #e4f4f1;
  text-transform: none;
  background-color: #9aaebb;
  opacity: 1 !important;
}

/* Add a border line underneath the active tab */
.mat-tab-label-active {
  border-bottom: 0px solid #31afe5;
  color: #31afe5;
}


/* Remove the box shadow and background color */
.mat-tab-group {
  border-radius: 5px, 5px;
  box-shadow: none;
}

.mat-tab-header {
  background-color: transparent;
  /* Remove background color if any */
}

/* Optionally, adjust hover styles */
.mat-tab-label:hover {
  color: white;
  /* Adjust hover color */
}

.custom-checkbox.mat-checkbox-checked .mat-checkbox-background,
.custom-checkbox.mat-accent .mat-checkbox-checked.mat-checkbox-background {
  background-color: #6C747E !important;
  /* Checked background color */
}

.custom-checkbox.mat-checkbox-indeterminate .mat-checkbox-background,
.custom-checkbox.mat-accent .mat-checkbox-checked.mat-checkbox-background {
  background-color: #6C747E !important;
  /* Checked background color */
}


.custom-checkbox .mat-checkbox-checkmark-path {
  stroke: #FFFFFF !important;
  /* Change to your desired color */
}

.custom-checkbox .mat-checkbox-frame {
  border-color: black !important;
  /* Change to your desired color */
  border-width: 1px;
}
