/* You can add global styles to this file, and also import other style files */


/* =========================================================================================== */
/*=                                  LOGIN PAGE CSS                                            =*/
/*=                                       START HERE                                           =*/
/* ===========================================================================================*/


body {
  font-family: 'Open Sans', sans-serif, Arial;
}

h1, h2, h3, h4, h5, h6, p, div, span {
  font-family: inherit;
}

button{
  height: 40px;
}

.clear-crosss {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

/* styles.css (or your main style file in the Angular project) */

/* Main container */
.login-main-container {
  background-color: white !important;
  font-family: 'Open Sans', sans-serif, Arial;
  background-size: cover !important;
  float: left;
  width: 100%;
  height: 95vh;
  background: url("assets/images/background/Background.png");
  background-size: cover;
}

/* style.css */

.custom-blue-button {
  cursor: pointer;
  display:flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 85px;
  height: 40px;
  background: #0C7BB8;
  border-radius: 4px;
  font-size: 13px;
  font-weight: normal;
  text-align: CENTER;
  color: #FFFFFF;
  line-height: 19px;
  border: none;

}

.custom-green-button {
  display:flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 62px;
  height: 40px;
  background: #51A550;
  border-radius: 4px;
  font-size: 13px;
  font-weight: normal;
  text-align: CENTER;
  color: #FFFFFF;
  line-height: 19px;
  border: none;

}

.custom-orange-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 16px;
  height: 40px;
  background: #F59120;
  border-radius: 4px;
  font-size: 13px;
  font-weight:bolder;
  color: #FFFFFF;
  line-height: 1.5; /* This can be adjusted as needed */
  border: none;
  cursor: pointer;
  text-shadow: 0px 3px 6px 0px rgba(0,0,0,0.30);
}

.custom-orange-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.search-button {
  /* width: 58px;
  height: 40px;
  background: #0c7bb8;
  border-radius: 0px 4px 4px 0px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);  */

  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 40px;
  background: #0c7bb8;
  border-radius: 0px 4px 4px 0px;
  font-size: 13px;
  font-weight:bolder;
  color: #FFFFFF;
  line-height: 1.5; /* This can be adjusted as needed */
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);

}

/* Header container */
.header-container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

/* Header detail */
.header-details {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  justify-content: space-between;
  align-items: center;
}

.header-details.with-gap {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px !important;
  column-gap: 5px !important;
  padding: 0px 15px !important;
}

/* Parent container for header form*/
.header-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 70%;
  gap: 20px;
}

/* Common styling for header form fields*/
.header-form-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 0 1 20%;
}

/* ==================================================================================== */
/*=                           OUTLINED BUTTIONS CSS                                    =*/
/*=                                   STARTS                                           =*/
/* ==================================================================================== */

/*CSS FOR THE OUTLINE BUTTONS AND COLORS*/


/* STYLING FOR PRIMARY BUTTONS */
.p-button {
  color: #212529;
  /* Text color is dark gray */
  background: #64B5F6;
  /* Default background color is a light blue tone */
  border: 1px solid #64B5F6;
  /* 1-pixel solid border with the same light blue tone */
  padding: 0.5rem 1rem;
  /* Padding for the button */
  font-size: 1rem;
  /* Font size of the text */
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  /* Smooth transitions on color and box-shadow changes */
  border-radius: 15px;
  /* Border radius for rounded corners */
}

/* Hover effect for primary buttons */
.p-button:not(:disabled):hover {
  background: #43a5f4;
  /* Darker blue tone on hover */
  color: #212529;
  /* Text color changes to dark gray */
  border-color: #43a5f4;
  /* Border color changes on hover */
}

/* Active effect for primary buttons */
.p-button:not(:disabled):active {
  background: #2396f2;
  /* Darker blue tone on click/activation */
  color: #212529;
  /* Text color changes to dark gray */
  border-color: #2396f2;
  /* Border color changes on click/activation */
}

/* Outlined primary buttons styles */
.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #64B5F6;
  /* Text color is the same light blue tone */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined primary buttons */
.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(100, 181, 246, 0.04);
  /* Light blue tint on hover with transparency */
  color: #64B5F6;
  /* Text color remains light blue tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined primary buttons */
.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(100, 181, 246, 0.16);
  /* Darker blue tint on click/activation with higher transparency */
  color: #64B5F6;
  /* Text color remains light blue tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Plain style for outlined primary buttons */
.p-button.p-button-outlined.p-button-plain {
  color: rgba(255, 255, 255, 0.6);
  /* Text color is a semi-transparent white */
  border-color: rgba(255, 255, 255, 0.6);
  /* Border color is a semi-transparent white */
}

/* Hover effect for plain outlined primary buttons */
.p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
  background: rgba(255, 255, 255, 0.03);
  /* Very light gray tint on hover with transparency */
  color: rgba(255, 255, 255, 0.6);
  /* Text color remains semi-transparent white */
}

/* Active effect for plain outlined primary buttons */
.p-button.p-button-outlined.p-button-plain:not(:disabled):active {
  background: rgba(255, 255, 255, 0.16);
  /* Darker gray tint on click/activation with higher transparency */
  color: rgba(255, 255, 255, 0.6);
  /* Text color remains semi-transparent white */
}



/* Focus effect for primary buttons */
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #93cbf9;
  /* Light box shadow when focused */
}

/* Additional styles for button label and icons */
.p-button .p-button-label {
  transition-duration: 0.2s;
  /* Smooth transition for label */
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
  /* Margin for left icon */
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
  /* Margin for right icon */
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
  /* Margin for bottom icon */
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
  /* Margin for top icon */
}


/* Rounded corners for buttons */
.p-button.p-button-rounded {
  border-radius: 2rem;
}


/* Small size for buttons */
.p-button.p-button-sm {
  font-size: 0.875rem;
  /* Font size for small buttons */
  padding: 0.4375rem 0.875rem;
  /* Padding for small buttons */
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
  /* Font size for small button icons */
}

/* Large size for buttons */
.p-button.p-button-lg {
  font-size: 1.25rem;
  /* Font size for large buttons */
  padding: 0.625rem 1.25rem;
  /* Padding for large buttons */
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
  /* Font size for large button icons */
}



/* Fluid style for icon-only buttons within a fluid container */
.p-fluid .p-button-icon-only {
  width: 2.357rem;
  /* Width for icon-only buttons within a fluid container */
}

/* Fluid style for button sets within a fluid container */
.p-fluid .p-buttonset {
  display: flex;
  /* Display button set items in a flex container */
}

/* Fluid style for individual buttons within a fluid button set */
.p-fluid .p-buttonset .p-button {
  flex: 1;
  /* Flex-grow property for equal distribution within a fluid button set */
}


/* Outlined secondary buttons styles */
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #78909c;
  /* Text color is a bluish tone */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined secondary buttons */
.p-button.p-button-secondary.p-button-outlined:not(:disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(120, 144, 156, 0.04);
  /* Light bluish tint on hover with transparency */
  color: #78909c;
  /* Text color remains bl

uish tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined secondary buttons */
.p-button.p-button-secondary.p-button-outlined:not(:disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(120, 144, 156, 0.16);
  /* Darker bluish tint on click/activation with higher transparency */
  color: #78909c;
  /* Text color remains bluish tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}



/* STYLING FOR SUCCESS BUTTONS */
.p-button.p-button-success,
.p-buttonset.p-button-success>.p-button,
.p-splitbutton.p-button-success>.p-button {
  color: #fff;
  /* Text color is white */
  background: #1be926;
  /* Brighter green tone for background */
  border: 1px solid #1be926;
  /* 1-pixel solid border with the brighter green tone */
}

/* Outlined success buttons styles */
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success>.p-button.p-button-outlined,
.p-splitbutton.p-button-success>.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #1be926;
  /* Text color is the brighter green tone */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined success buttons */
.p-button.p-button-success.p-button-outlined:not(:disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(:disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(102, 187, 106, 0.04);
  /* Light green tint on hover with transparency */
  color: #1be926;
  /* Text color remains the brighter green tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined success buttons */
.p-button.p-button-success.p-button-outlined:not(:disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(:disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(102, 187, 106, 0.16);
  /* Darker green tint on click/activation with higher transparency */
  color: #1be926;
  /* Text color remains the brighter green tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}


/* Outlined info buttons styles */
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #1e88e5;
  /* Text color is a light blue tone */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined info buttons */
.p-button.p-button-info.p-button-outlined:not(:disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(:disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(129, 212, 250, 0.04);
  /* Light blue tint on hover with transparency */
  color: #1e88e5;
  /* Text color remains light blue tone */


  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined info buttons */
.p-button.p-button-info.p-button-outlined:not(:disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(:disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(129, 212, 250, 0.16);
  /* Darker blue tint on click/activation with higher transparency */
  color: #1e88e5;
  /* Text color remains light blue tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}



/* Outlined help buttons styles */
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #d020ef;
  /* Text color is a light purple tone */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined help buttons */
.p-button.p-button-help.p-button-outlined:not(:disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(:disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(206, 147, 216, 0.04);
  /* Light purple tint on hover with transparency */
  color: #d020ef;
  /* Text color remains light purple tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined help buttons */
.p-button.p-button-help.p-button-outlined:not(:disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(:disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(206, 147, 216, 0.16);
  /* Darker purple tint on click/activation with higher transparency */
  color: #d020ef;
  /* Text color remains light purple tone */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Outlined warning buttons styles */
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning>.p-button.p-button-outlined,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #ff7f00;
  /* Text color is orange */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined warning buttons */
.p-button.p-button-warning.p-button-outlined:not(:disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(255, 127, 0, 0.04);
  /* Light orange tint on hover with transparency */
  color: #ff7f00;
  /* Text color remains orange */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined warning buttons */
.p-button.p-button-warning.p-button-outlined:not(:disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(:disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(255, 127, 0, 0.16);
  /* Darker orange tint on click/activation with higher transparency */
  color: #ff7f00;
  /* Text color remains orange */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}


/* Outlined tertiary buttons styles */
.p-button.p-button-tertiary.p-button-outlined,
.p-buttonset.p-button-tertiary>.p-button.p-button-outlined,
.p-splitbutton.p-button-tertiary>.p-button.p-button-outlined {
  background-color: transparent;
  /* Transparent background for outlined buttons */
  color: #ff69b4;
  /* Text color is pink */
  border: 1px solid;
  /* 1-pixel solid border */
}

/* Hover effect for outlined tertiary buttons */
.p-button.p-button-tertiary.p-button-outlined:not(:disabled):hover,
.p-buttonset.p-button-tertiary>.p-button.p-button-outlined:not(:disabled):hover,
.p-splitbutton.p-button-tertiary>.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(255, 105, 180, 0.04);
  /* Light pink tint on hover with transparency */
  color: #ff69b4;
  /* Text color remains pink */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}

/* Active effect for outlined tertiary buttons */
.p-button.p-button-tertiary.p-button-outlined:not(:disabled):active,
.p-buttonset.p-button-tertiary>.p-button.p-button-outlined:not(:disabled):active,
.p-splitbutton.p-button-tertiary>.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(255, 105, 180, 0.16);
  /* Darker pink tint on click/activation with higher transparency */
  color: #ff69b4;
  /* Text color remains pink */
  border: 1px solid;
  /* Border remains 1-pixel solid */
}




.p-button .p-button-label {
  transition-duration: 0.2s;
}

.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-link:not(:disabled):hover .p-button-label {
  text-decoration: underline;
}

/* =========================================================================================== */
/*=                                  OUTLINED BUTTIONS CSS                                    =*/
/*=                                       ENDS HERE                                           =*/
/* ===========================================================================================*/

/* =========================================================================================== */
/*=                                  STATUS LABELS CSS                                        =*/
/*=                                       STARTS HERE                                           =*/
/* ===========================================================================================*/
.status-badge {
  border-radius: 8px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.status-badge.status-success {
  background: #c0f2c1;
  color: #228d29;
  margin-top: 5px;
  margin-bottom: 5px;
}

.status-badge.status-error {
  background: #FFCDD2;
  color: #C63737;
}

.status-badge.status-pending {
  background: #feddaf;
  color: #8A5340;
}

.status-badge.status-inprogress {
  background: #e5c8f8;
  color: #8f1fda;
  margin-top: 5px;
  margin-bottom: 5px;
}



/* =========================================================================================== */
/*=                                  STATUS LABELS CSS                                        =*/
/*=                                       ENDS HERE                                           =*/
/* ===========================================================================================*/
.mat-flat-button.mat-button-disabled {
  background-color: #d3d3d3 !important;
  color: #9e9e9e !important;
  border: 1px solid #bcbcbc !important;
  cursor: not-allowed;
}

.mat-flat-button.mat-button-disabled:hover {
  background-color: #d3d3d3 !important;
}


/* PAGE HEADER SUB URL NAME CSS */
.subUrl {

  height: 20px;
  border-radius: 0px;
  font-size: 13px;
  font-family: Microsoft YaHei, Microsoft YaHei-Normal;
  font-weight: normal;
  text-align: LEFT;
  color: #757575;
  line-height: 20px;
}

mat-row, mat-cell {
  cursor: default !important;
}

mat-row.expanded mat-table .mat-row,
mat-row.expanded mat-table .mat-cell {
  cursor: default !important;
}

/* Default state: Keep the arrow white but slightly transparent */
.mat-sort-header-arrow {
  color: white !important;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* On hover: Make it fully visible */
.mat-sort-header:hover .mat-sort-header-arrow {
  opacity: 1;
}

/* When column is sorted (active state), keep the arrow white and fully visible */
.mat-sort-header.mat-sort-active .mat-sort-header-arrow {
  color: white !important;
  opacity: 1 !important;
}

/* apply background-color to the rows in the mat table */
mat-table .mat-row:nth-child(odd) {
  background-color:#fafafa !important;
}

mat-table .mat-row:nth-child(even) {
  background-color: #ffffff !important;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background {
  background-color: #ff5722 !important;
}

.alignCashValue{
  display: flex;
  justify-content: right;
  flex-wrap: wrap; /* Allows text to wrap */
  word-break: break-word; /* Ensures long words wrap */
  overflow-wrap: break-word;
  white-space: normal;
}

.ColumnPadding{
  padding-right: 20px;
}

.alignCenter{
  text-align: center;
  justify-content: center; /* For flex-based alignment */
}

.percentage{
  display: flex;
  justify-content: right;
}

.mat-cell.alignCellCenter,
.mat-header-cell.alignCellCenter {
  text-align: center;
  justify-content: center;
  padding-right: 20px !important; /* Forcefully override */
}

.word-wrap{
    word-wrap: break-word !important;
    white-space: unset !important;
    overflow-wrap: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


@font-face {
  font-family: 'DS DIGI';
  src: url('assets/fonts/DS_DIGI.ttf') format('truetype');
}

@font-face {
  font-family: 'Houschka Pro';
  src: url('assets/fonts/houschka_pro_medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/opensans_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Orange Juice';
  src: url('assets/fonts/orange_juice.ttf') format('truetype');
}

@font-face {
  font-family: 'Shine in Valentine';
  src: url('assets/fonts/Shine_in_Valentine.ttf') format('truetype');
}

@font-face {
  font-family: 'Tox Typewriter';
  src: url('assets/fonts/Tox_Typewriter.ttf') format('truetype');
}

@font-face {
  font-family: 'Type Machine';
  src: url('assets/fonts/Type_Machine.ttf') format('truetype');
}
